<div class="navbar">
  <div class="navbar__item navbar__item--left">
    <img src="/assets/images/svg/logo-gencat.svg" alt="Gencat Logo" class="navbar__logo">
  </div>
  <div class="navbar__item navbar__item--right">
    <button class="navbar__dropdown" tabindex="0" aria-expanded="false" [attr.aria-expanded]="isDropdownOpen"
      (click)="toggleDropdown()">
      <div class="navbar__dropdown-text" role="alert" aria-live="polite" aria-atomic="true">
        {{ currentLanguage() | uppercase }}
      </div>
      <i class="navbar__dropdown-icon"></i>
      <div class="navbar__dropdown-menu" [class.visible]="isDropdownOpen">
        @for (lang of languages; track $index) {
        <button class="navbar__dropdown-item" (click)="selectLanguage(lang)">
          {{ lang | uppercase }}
        </button>
        }
      </div>
    </button>
  </div>
</div>