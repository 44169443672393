import { UpperCasePipe } from '@angular/common';
import { Component, HostListener, inject } from '@angular/core';
import { LanguageService } from '@shared/services/language.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [UpperCasePipe]
})
export class HeaderComponent {
 private readonly languageService = inject(LanguageService);

  currentLanguage = this.languageService.currentLanguageSignal;
  languages = ['CA', 'ES', 'EN', 'OCI'];
  isDropdownOpen = false;



  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  selectLanguage(lang: string): void {
    this.languageService.changeLanguage(lang.toLowerCase());
  }


  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
     if (!targetElement.closest('.navbar__dropdown')) {
     this.isDropdownOpen = false;

  }

}}



