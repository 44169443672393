import { Injectable, signal, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private readonly translate = inject(TranslateService);
  public readonly currentLanguageSignal = signal<string>('');
  private readonly supportedLanguages = ['ca', 'en', 'es', 'oci'];

  constructor() {
    const storedLang = typeof localStorage !== 'undefined' ? localStorage.getItem('language') : null;
    const initialLang = this.getValidLanguage(storedLang ?? 'ca');

    this.currentLanguageSignal.set(initialLang);
    this.translate.setDefaultLang('ca');
    this.translate.use(initialLang);

    this.translate.onLangChange.subscribe((event) => {

      this.currentLanguageSignal.set(event.lang);
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem('language', event.lang);
      }
    });
  }

  changeLanguage(languageCode: string): void {
    const langToUse = this.getValidLanguage(languageCode);
    this.translate.use(langToUse);
  }

  getValidLanguage(languageCode: string): string {
    if (!this.supportedLanguages.includes(languageCode)) {
      console.warn(`Idioma no soportado: ${languageCode}. Cambiando a 'ca'.`);
      return 'ca';
    }
    return languageCode;
  }

  translateKey(key: string): string {
    return this.translate.instant(key);
  }

}


