<toast-component id="global-toast"></toast-component>

<div class="layout">
  <header class="header">
    <app-header />
  </header>
  <main class="content">
    <router-outlet />
  </main>
  <footer class="footer">
    <app-footer />
  </footer>
</div>
