{
  "name": "instant-video-waitingroom",
  "version": "1.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "jest --verbose",
    "test:coverage": "jest --coverage",
    "test:watch": "jest --watch",
    "serve:ssr:instant-video-calendar": "node dist/instant-video-calendar/server/server.mjs"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.0",
    "@angular/common": "^18.2.0",
    "@angular/compiler": "^18.2.0",
    "@angular/core": "^18.2.0",
    "@angular/forms": "^18.2.0",
    "@angular/platform-browser": "^18.2.0",
    "@angular/platform-browser-dynamic": "^18.2.0",
    "@angular/platform-server": "^18.2.0",
    "@angular/router": "^18.2.0",
    "@angular/ssr": "^18.2.12",
    "@ngx-translate/core": "^16.0.3",
    "@ngx-translate/http-loader": "^16.0.0",
    "express": "^4.18.2",
    "gencat": "file:./Gene-library/3693.00-sdc-eixam-library-master",
    "gencat-loader": "file:./src/loader",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.12",
    "@angular/cli": "^18.2.12",
    "@angular/compiler-cli": "^18.2.0",
    "@types/express": "^4.17.17",
    "@types/jest": "^29.5.14",
    "@types/node": "^18.18.0",
    "jest": "^29.7.0",
    "jest-preset-angular": "^14.3.3",
    "ts-node": "^10.9.2",
    "typescript": "~5.5.2"
  }
}
